import React, { useEffect, useMemo } from 'react';
import { 
  Container, 
  CurrentOrderButtons, 
  CurrentOrderContainer, 
  CustomerContainer, 
  DeactivateContainer, 
  DetailsEditBoxes, 
  DetailsWrapper, 
  GrayContainer, 
  HistoryChildrenContainer, 
  HistoryPageTitle, 
  HistorySection, 
  HistoryTableWrapper, 
  Image, 
  ImageBox, 
  OrderIdBox, 
  PageTitleWrapper, 
  ProductsBox, 
  Section, 
  SmallActionBoxesWrapper, 
  SmallImageBox, 
  StyledCustomerContactBox,
  StyledDetailsInfoGroup,
  StyledTransactionProductBox,
} from './subscriptionDetails.styled';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  UseRedirect, 
  useRedirect, 
  PageTitle, 
  PageTitleSize, 
  DetailsTable,
  ComponentColorTheme,
  UseParsers,
  useParsers,
  ProgressBar,
  DetailsEditBox,
  DetailsRows,
  IconName,
  SectionHeader,
  AccordionExtended,
  Breakpoint,
  HistorySimpleTable,
  HistorySimpleTableRow,
  Button,
  ButtonTheme,
  SmallActionBox,
} from '@chic-loyalty/ui';
import { FileFromViews, RoutingPath, SubscriptionOrderStatus } from '@chic/enums';
import { 
  SubscriptionDelivery, 
  SubscriptionOrder, 
  SubscriptionPayment, 
  SubscriptionProduct,
  UseStatics,
  UseSubscriptionsUtils, 
} from '@chic/models';
import { useMediaQuery } from 'react-responsive';
import { useStatics, useSubscriptionsUtils, useTitle } from '@chic/hooks';
import { deliveryStatusColor, paymentStatusColor } from './subscriptionDetails.const';
import { AmendmentsTableColumnName, HistoryDeliveriesTableColumnName, HistoryPaymentsTableColumnName } from './subscriptionDetails.enum';
import { orderStatusColor } from '@chic/constans';
import { Location, NavigateFunction, Params, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSubscriptionDetailsActions, useSubscriptionDetailsActionsPermissions, useSubscriptionDetailsData } from './hooks';
import {
  UseSubscriptionDetailsActions,
  UseSubscriptionDetailsActionsPermissions,
  UseSubscriptionDetailsData,
} from './subscriptionDetails.types';

export const SubscriptionDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { parsePrice }: UseParsers = useParsers();
  const { subscriptionId: rawSubscriptionId }: Params = useParams();
  const { 
    getDeliveryNameByType, 
    getPaymentNameByType,
    getDeliveryStatusByType,
    getPaymentStatusByType,
    getOrderStatusByType,
  }: UseStatics = useStatics();
  const navigate: NavigateFunction = useNavigate();
  const subscriptionId: number | null = useMemo(
    (): number | null => {
      const parsedId: number = parseInt(rawSubscriptionId ?? '', 10);

      return isNaN(parsedId)
        ? null
        : parsedId;
    },
    [rawSubscriptionId],
  );
  useEffect(
    (): void => {
      if (subscriptionId === null) {
        navigate(RoutingPath.SubscriptionList);
      }
    },
    [subscriptionId],
  );
  const {
    amendmentsTable,
    subscriptionDetails,
    subscriptionOrders,
    currentOrder,
    detailsRowsData,
    orderProgressBarStatuses,
  }: UseSubscriptionDetailsData = useSubscriptionDetailsData(subscriptionId);
  const {
    cancelOrder,
    manualConfirmDelivery,
    cancelWholeSubscription,
    manualAcceptPayment,
  }: UseSubscriptionDetailsActions = useSubscriptionDetailsActions();
  const { actionsPermissions }: UseSubscriptionDetailsActionsPermissions = useSubscriptionDetailsActionsPermissions(subscriptionId);
  const { transformLocationToDeliveryAddress }: UseSubscriptionsUtils = useSubscriptionsUtils();
  useTitle(t('chic.management.subscriptionDetailsView.title'));
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const currency: string = 'zł';
  const location: Location = useLocation();

  useEffect(
    (): void => {
      if (subscriptionId === null) {
        navigate(RoutingPath.SubscriptionList);
      }
    },
    [subscriptionId],
  );

  const goBack: () => void = (): void => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      redirect(RoutingPath.SubscriptionList);
    }
  };

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle 
          size={PageTitleSize.Big} 
          label={t('chic.management.subscriptionDetailsView.pageTitle')} 
          onClick={(): void => goBack()}
        />
      </PageTitleWrapper>
      {isMobile && (
        <ImageBox>
          <Image src={FileFromViews.SubscriptionDetailsViewImage} />
        </ImageBox>
      )}
      <Section>
        <PageTitle label={t('chic.management.subscriptionDetailsView.customerDetails.title')} />
        <CustomerContainer>
          <StyledCustomerContactBox 
            items={[
              {
                value: subscriptionDetails?.customer.email ?? '',
                label: t('chic.management.subscriptionDetailsView.customerContactBox.email'),
                icon: IconName.Mails,
              },
              {
                value: subscriptionDetails?.customer.phone ?? '',
                label: t('chic.management.subscriptionDetailsView.customerContactBox.phone'),
                icon: IconName.PhoneShake,
              },
            ]}          
          />
          {!isMobile && (
            <ImageBox>
              <Image src={FileFromViews.SubscriptionDetailsViewImage} />
            </ImageBox>
          )}
        </CustomerContainer>
      </Section>
      <Section>
        <PageTitle label={t('chic.management.subscriptionDetailsView.planDetails.title')} />
        <DetailsWrapper>
          <DetailsRows rows={detailsRowsData} />
        </DetailsWrapper>
        {!!actionsPermissions.createOrder && !!subscriptionId && (
          <Button 
            label={t('chic.management.subscriptionDetailsView.createOrder.label')} 
            buttonTheme={ButtonTheme.ICPrimary} 
            onClick={(): void => redirect(RoutingPath.SubscriptionOrder, { subscriptionId })} 
          />
        )}
      </Section>
      <Section>
        <PageTitle label={t('chic.management.subscriptionDetailsView.products.title')} />
        <GrayContainer>
          {subscriptionDetails?.contract.products.map((product: SubscriptionProduct): JSX.Element => (
            <StyledTransactionProductBox 
              name={product.productName}
              category={product.categoryName}
              ean={product.ean}
              amount={product.amount}
              image={product.image ?? undefined}
              price={product.unitPrice}
              colorTheme={ComponentColorTheme.IC}
              key={product.productId}
            />
          ))}
        </GrayContainer>
      </Section>
      {!!currentOrder && (
        <Section>
          <PageTitle label={t('chic.management.subscriptionDetailsView.currentOrder.title')}>
            <OrderIdBox>
              {t('chic.management.subscriptionDetailsView.currentOrder.id', { id: currentOrder.id })}
            </OrderIdBox>
          </PageTitle>
          <CurrentOrderContainer>
            <GrayContainer>
              <ProgressBar 
                tabs={orderProgressBarStatuses}
                activeStepName={currentOrder.status}
                errorMessage={currentOrder.status === SubscriptionOrderStatus.Failed 
                  ? t('chic.management.subscriptionDetailsView.currentOrder.status.failed')
                  : undefined
                }
              />
            </GrayContainer>
            <GrayContainer>
              <DetailsEditBoxes>
                {!!currentOrder.deliveries[currentOrder.deliveries.length - 1] && (
                  <DetailsEditBox
                    title={t('chic.management.subscriptionDetailsView.currentOrder.deliveryAddress')} 
                    textContent={currentOrder.deliveries[currentOrder.deliveries.length - 1].location 
                      ? transformLocationToDeliveryAddress(currentOrder.deliveries[currentOrder.deliveries.length - 1].location)
                      : t('chic.management.global.noData')
                    } 
                    badge={currentOrder.deliveries[currentOrder.deliveries.length - 1].cost === 0 
                      ? t('chic.management.subscriptionDetailsView.currentOrder.delivery.free')
                      : undefined}
                  />
                )}
                {!!currentOrder.payments[currentOrder.payments.length - 1] && (
                  <DetailsEditBox 
                    title={t('chic.management.subscriptionDetailsView.currentOrder.paymentMethod')} 
                    textContent={getPaymentNameByType(
                      currentOrder.payments[currentOrder.payments.length - 1]?.type,
                    ) ?? t('chic.management.global.noData')} 
                    badge={String(currentOrder.payments[currentOrder.payments.length - 1]?.id)}
                    buttonSettings={
                      (subscriptionId && actionsPermissions.manualAcceptPayment)
                        ? {
                          label: t('chic.management.subscriptionDetailsView.actions.manualAcceptPayment.buttonLabel'),
                          onClick: (): void => {
                            manualAcceptPayment(subscriptionId, currentOrder.payments[currentOrder.payments.length - 1]?.id);
                          },
                        }
                        : undefined
                    }
                  />
                )}
              </DetailsEditBoxes>
              <ProductsBox>
                <SectionHeader 
                  title={t('chic.management.subscriptionDetailsView.productsBox.title')} 
                  colorTheme={ComponentColorTheme.IC} 
                />
                {currentOrder.products.map((product: SubscriptionProduct): JSX.Element => (
                  <StyledTransactionProductBox 
                    name={product.productName}
                    category={product.categoryName}
                    ean={product.ean}
                    amount={product.amount}
                    image={product.image ?? undefined}
                    price={product.unitPrice}
                    colorTheme={ComponentColorTheme.IC}
                    key={product.productId}
                  />
                ))}
              </ProductsBox>
              {!!currentOrder.deliveries[currentOrder.deliveries.length - 1]?.trackingUrl && (
                <SmallActionBoxesWrapper>
                  <SmallActionBox 
                    label={t('chic.management.subscriptionDetailsView.actions.track.boxLabel')}
                    icon={IconName.Box} 
                    buttonSettings={{
                      label: t('chic.management.subscriptionDetailsView.actions.track.buttonLabel'),
                      externalUrl: currentOrder.deliveries[currentOrder.deliveries.length - 1].trackingUrl!,
                    }} 
                    buttonTheme={ButtonTheme.ICPrimary} 
                  />
                </SmallActionBoxesWrapper>
              )}
              {(!!subscriptionId
                && (actionsPermissions.cancelOrder || actionsPermissions.manualConfirmDelivery )
              ) && (
                <CurrentOrderButtons>
                  {actionsPermissions.manualConfirmDelivery && (
                    <Button 
                      label={t('chic.management.subscriptionDetailsView.actions.manualConfirmDelivery.buttonLabel')}
                      onClick={(): void => manualConfirmDelivery(
                        subscriptionId,
                        currentOrder.deliveries[currentOrder.deliveries.length - 1].id,
                      )}
                      buttonTheme={ButtonTheme.ICPrimary} 
                    />
                  )}
                  {actionsPermissions.cancelOrder && (
                    <Button
                      label={t('chic.management.subscriptionDetailsView.actions.cancelOrder.buttonLabel')}
                      onClick={(): void => cancelOrder(subscriptionId, currentOrder.id)}
                      buttonTheme={ButtonTheme.ICSecondary} 
                    />
                  )}
                </CurrentOrderButtons>
              )}
            </GrayContainer>
          </CurrentOrderContainer>
        </Section>
      )}
      {!!subscriptionOrders.length && (
        <HistorySection>
          <HistoryPageTitle label={t('chic.management.subscriptionDetailsView.subscriptionOrders.title')} />
          {subscriptionOrders.map((order: SubscriptionOrder): JSX.Element => {
            const lastDelivery: SubscriptionDelivery | undefined = order.deliveries[order.deliveries.length - 1] ?? undefined;

            return (
              <AccordionExtended 
                key={order.id}
                headerCells={[
                  { label: String(order.id) },
                  ...(!isMobile ? [
                    {
                      icon: IconName.Box,
                      label: `${parsePrice(order.price)} ${currency}`,
                    },
                    { 
                      icon: IconName.Date,
                      label: order.plannedDeliveryDate ?? t('chic.management.global.noData') ?? undefined,
                    },
                    { 
                      label: getOrderStatusByType(order.status), 
                      labelColor: orderStatusColor[order.status],
                    },
                    { label: lastDelivery?.type ? getDeliveryNameByType(lastDelivery.type) : t('chic.management.global.noData') },
                    { label: lastDelivery?.trackingNumber ? lastDelivery.trackingNumber : t('chic.management.global.noData') },
                  ] : []),
                ]} 
                colorTheme={ComponentColorTheme.IC}      
              >
                <HistoryChildrenContainer>
                  {!!isMobile && (
                    <StyledDetailsInfoGroup items={[
                      { 
                        label: t('chic.management.subscriptionDetailsView.historyDetailsInfoGroup.id'),
                        value: String(order.id), 
                      },
                      {
                        label: t('chic.management.subscriptionDetailsView.historyDetailsInfoGroup.price'),
                        value: `${parsePrice(order.price)} ${currency}`,
                      },
                      { 
                        label: t('chic.management.subscriptionDetailsView.historyDetailsInfoGroup.plannedDeliveryDate'),
                        value: order.plannedDeliveryDate ?? t('chic.management.global.noData'),
                      },
                      { 
                        label: t('chic.management.subscriptionDetailsView.historyDetailsInfoGroup.status'),
                        value: getOrderStatusByType(order.status), 
                        valueColor: orderStatusColor[order.status],
                      },
                      { 
                        label: t('chic.management.subscriptionDetailsView.historyDetailsInfoGroup.deliveryType'),
                        value: lastDelivery?.type ? getDeliveryNameByType(lastDelivery.type) : t('chic.management.global.noData'), 
                      },
                      { 
                        label: t('chic.management.subscriptionDetailsView.historyDetailsInfoGroup.trackingNumber'),
                        value: lastDelivery?.trackingNumber ? lastDelivery.trackingNumber : t('chic.management.global.noData'), 
                      },
                    ]} />
                  )}
                  <SectionHeader 
                    title={t('chic.management.subscriptionDetailsView.productsBox.title')} 
                    colorTheme={ComponentColorTheme.IC} 
                  />
                  {order.products.map((product: SubscriptionProduct): JSX.Element => (
                    <StyledTransactionProductBox 
                      name={product.productName}
                      category={product.categoryName}
                      ean={product.ean}
                      amount={product.amount}
                      image={product.image ?? undefined}
                      price={product.unitPrice}
                      colorTheme={ComponentColorTheme.IC}
                      key={product.productId}
                    />
                  ))}
                  <HistoryTableWrapper>
                    {!!order.payments.length && (
                      <HistorySimpleTable 
                        title={t('chic.management.subscriptionDetailsView.historyTable.payments.title')}
                        headers={[
                          {
                            name: HistoryPaymentsTableColumnName.Id,
                            label: t('chic.management.subscriptionDetailsView.historyTable.payments.id'),
                          },
                          {
                            name: HistoryPaymentsTableColumnName.Type,
                            label: t('chic.management.subscriptionDetailsView.historyTable.payments.type'),
                          },
                          {
                            name: HistoryPaymentsTableColumnName.Status,
                            label: t('chic.management.subscriptionDetailsView.historyTable.payments.status'),
                          },
                          {
                            name: HistoryPaymentsTableColumnName.Price,
                            label: t('chic.management.subscriptionDetailsView.historyTable.payments.price'),
                          },
                        ]}
                        rows={order.payments.map((payment: SubscriptionPayment): HistorySimpleTableRow => ({
                          cells: [
                            {
                              name: HistoryPaymentsTableColumnName.Id,
                              label: String(payment.id),
                            },
                            {
                              name: HistoryPaymentsTableColumnName.Type,
                              label: getPaymentNameByType(payment.type),
                            },
                            {
                              name: HistoryPaymentsTableColumnName.Status,
                              label: getPaymentStatusByType(payment.status),
                              color: paymentStatusColor[payment.status],
                            },
                            {
                              name: HistoryPaymentsTableColumnName.Price,
                              label: `${parsePrice(payment.amount)} ${currency}`,
                              isHighlighted: true,
                            },
                          ],
                        }))}
                      />
                    )}
                    {!isMobile && (
                      <SmallImageBox>
                        <Image src={FileFromViews.SubscriptionDetailsViewImage} />
                      </SmallImageBox>
                    )}
                  </HistoryTableWrapper>
                  {!!order.deliveries.length && (
                    <HistorySimpleTable 
                      title={t('chic.management.subscriptionDetailsView.historyTable.deliveries.title')}
                      headers={[
                        {
                          name: HistoryDeliveriesTableColumnName.Type,
                          label: t('chic.management.subscriptionDetailsView.historyTable.deliveries.type'),
                        },
                        {
                          name: HistoryDeliveriesTableColumnName.Status,
                          label: t('chic.management.subscriptionDetailsView.historyTable.deliveries.status'),
                        },
                        {
                          name: HistoryDeliveriesTableColumnName.Price,
                          label: t('chic.management.subscriptionDetailsView.historyTable.deliveries.price'),
                        },
                        {
                          name: HistoryDeliveriesTableColumnName.Address,
                          label: t('chic.management.subscriptionDetailsView.historyTable.deliveries.address'),
                        },
                      ]}
                      rows={order.deliveries.map((delivery: SubscriptionDelivery): HistorySimpleTableRow => ({
                        cells: [
                          {
                            name: HistoryDeliveriesTableColumnName.Type,
                            label: getDeliveryNameByType(delivery.type),
                          },
                          {
                            name: HistoryDeliveriesTableColumnName.Status,
                            label: getDeliveryStatusByType(delivery.status),
                            color: deliveryStatusColor[delivery.status],
                          },
                          {
                            name: HistoryDeliveriesTableColumnName.Price,
                            label: `${parsePrice(delivery.cost)} ${currency}`,
                          },
                          {
                            name: HistoryDeliveriesTableColumnName.Address,
                            label: transformLocationToDeliveryAddress(delivery.location),
                          },
                        ],
                      }))}
                    />
                  )}
                </HistoryChildrenContainer>
              </AccordionExtended>
            );
          })}
        </HistorySection>
      )}
      {!!amendmentsTable.length && (
        <Section>
          <PageTitle label={t('chic.management.subscriptionDetailsView.amendments.title')} />
          <DetailsTable 
            headers={[{
              name: AmendmentsTableColumnName.Id,
              label: t('chic.management.subscriptionDetailsView.amendmentsTable.id'),
            }, {
              name: AmendmentsTableColumnName.Type,
              label: t('chic.management.subscriptionDetailsView.amendmentsTable.type'),
            }, {
              name: AmendmentsTableColumnName.Status,
              label: t('chic.management.subscriptionDetailsView.amendmentsTable.status'),
            }, {
              name: AmendmentsTableColumnName.CreateDatetime,
              label: t('chic.management.subscriptionDetailsView.amendmentsTable.createDatetime'),
            }, {
              name: AmendmentsTableColumnName.UpdateDatetime,
              label: t('chic.management.subscriptionDetailsView.amendmentsTable.updateDatetime'),
            }]}
            tableData={amendmentsTable}
            columnsVisibleOnMobile={[AmendmentsTableColumnName.Id, AmendmentsTableColumnName.Status]}
            colorTheme={ComponentColorTheme.IC}
          />
        </Section>
      )}
      {subscriptionDetails?.id && (
        <DeactivateContainer>
          <Button 
            label={t('chic.management.subscriptionDetailsView.actions.cancelSubscription.buttonLabel')}
            onClick={(): void => cancelWholeSubscription(subscriptionDetails.id)} 
            buttonTheme={ButtonTheme.ICPrimaryRed} 
            disabled={!actionsPermissions.cancelWholeSubscription}
          />
        </DeactivateContainer>
      )}
    </Container>
  );
};
