import { TransProps, useTranslation } from 'react-i18next';
import { SubscriptionPickupPointType } from '@chic/enums';
import { SubscriptionDeliveryAddress, UseSubscriptionsUtils } from '@chic/models';

export const useSubscriptionsUtils: () => UseSubscriptionsUtils = (): UseSubscriptionsUtils => {
  const { t }: TransProps<never> = useTranslation();

  const transformLocationToDeliveryAddress: (location: SubscriptionDeliveryAddress) => string = (
    location: SubscriptionDeliveryAddress,
  ): string => {
    if (location.pickupPoint) {
      switch (location.pickupPoint.type) {
        case SubscriptionPickupPointType.InpostMachine:
          return t(
            'chic.management.useSubscriptionDetails.transformLocationToDeliveryAddress.inpostMachine', 
            { id: location.pickupPoint.externalId },
          );
        case SubscriptionPickupPointType.DpdPickupPoint:
          return t(
            'chic.management.useSubscriptionDetails.transformLocationToDeliveryAddress.dpdPickupPoint', 
            { id: location.pickupPoint.externalId },
          );
        case SubscriptionPickupPointType.PosSelfPickup:
          return t(
            'chic.management.useSubscriptionDetails.transformLocationToDeliveryAddress.posSelfPickup', 
            { id: location.pickupPoint.externalId },
          );
        default: 
          return '';
      }
    } else {
      return `${location.name},
      ${location.address},
      ${location.postalCode} ${location.city}`;
    }   
  };
  
  return { transformLocationToDeliveryAddress };
};
