import { 
  DetailsInfoSettings, 
  useNotifications, 
  UseNotifications, 
  useParsers, 
  UseParsers, 
  useRedirect, 
  UseRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryKey, RoutingPath } from '@chic/enums';
import { UseSubscriptionOrder } from './subscriptionOrder.types';
import { useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { FrontendApiError, SubscriptionDetails, SubscriptionProduct, UseSubscriptionsUtils } from '@chic/models';
import { getSubscriptionDetails, makeSubscriptionOrder, simulateSubscription } from '@chic/api';
import { emptyRequest } from '@chic/utils';
import { SubscriptionProductAmount, SubscriptionSimulation } from '@chic/interfaces';
import { useSubscriptionsUtils } from '@chic/hooks';

export const useSubscriptionOrder: (id: string) => UseSubscriptionOrder = (id: string): UseSubscriptionOrder => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { parsePrice }: UseParsers = useParsers();
  const { transformLocationToDeliveryAddress }: UseSubscriptionsUtils = useSubscriptionsUtils();
  const { addToast, showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const [subscriptionDetails, setSubscriptionDetails]: UseState<SubscriptionDetails | null> = useState<SubscriptionDetails | null>(null);
  const [simulationDetails, setSimulationDetails]: UseState<SubscriptionSimulation | null> = useState<SubscriptionSimulation | null>(null);
  const [products, setProducts]: UseState<SubscriptionProduct[]> = useState<SubscriptionProduct[]>([]);
  const currency: string = 'zł';

  const subscriptionId: number | null = useMemo(
    (): number | null => {
      const parsedId: number = parseInt(id ?? '', 10);

      return isNaN(parsedId)
        ? null
        : parsedId;
    },
    [id],
  );

  useEffect(
    (): void => {
      if (subscriptionId === null) {
        redirect(RoutingPath.SubscriptionList);
      }
    },
    [subscriptionId],
  );
  
  const goBack: () => void = (): void => {
    redirect(RoutingPath.SubscriptionDetails, { subscriptionId: id });
  };

  useQuery(
    [QueryKey.SubscriptionDetails],
    (): Promise<SubscriptionDetails | void> => subscriptionId
      ? getSubscriptionDetails(subscriptionId)
      : emptyRequest(),
    {
      enabled: !!subscriptionId,
      onSuccess: (data: SubscriptionDetails | void): void => {
        if (!data) {
          return;
        }

        setSubscriptionDetails(data);
        setProducts(data.contract.products);

        simulateSubscription(data.id, { 
          products: data.contract.products.map((product: SubscriptionProduct): SubscriptionProductAmount => ({
            id: product.productId,
            amount: product.amount,
          })), 
          deliveryType: data.contract.delivery.type, 
        })
          .then(setSimulationDetails)
          .catch((error: FrontendApiError): void => addToast({ content: error.firstErrorTranslated ?? error.message ?? '' }));
      },
      onError: (error: FrontendApiError): void => addToast({ content: error.firstErrorTranslated ?? error.message ?? '' }),
    },
  );

  const detailsInfoSettings: DetailsInfoSettings[] = useMemo(
    (): DetailsInfoSettings[] => {
      if (!simulationDetails || !subscriptionDetails) {
        return [];
      }

      return [
        {
          label: t('chic.management.useSubscriptionOrder.detailsInfoSettings.price'),
          value: `${parsePrice(simulationDetails.productsPrice.regular)} ${currency}`,
        },
        {
          label: t('chic.management.useSubscriptionOrder.detailsInfoSettings.discount'),
          value: subscriptionDetails.contract.discountPercentage ? `${subscriptionDetails.contract.discountPercentage}%` : '-',
        },
        {
          label: t('chic.management.useSubscriptionOrder.detailsInfoSettings.delivery'),
          value: simulationDetails.deliveryPrice.discounted !== 0 
            ? `${parsePrice(simulationDetails.deliveryPrice.discounted ?? 0)} ${currency}`
            : t('chic.management.global.free'),
        },
        {
          label: t('chic.management.useSubscriptionOrder.detailsInfoSettings.fullPrice'),
          value: `${parsePrice(simulationDetails.productsPrice.discounted + simulationDetails.deliveryPrice.discounted)} ${currency}`,
        },
      ];
    },
    [simulationDetails],
  );

  const deliveryAddress: string = useMemo(
    (): string => {
      if (!subscriptionDetails) {
        return '';
      }

      return transformLocationToDeliveryAddress(subscriptionDetails.contract.delivery);
    },
    [subscriptionDetails],
  );

  const deliveryBadge: string | undefined = useMemo(
    (): string | undefined => {
      if (!simulationDetails || !subscriptionDetails) {
        return;
      }

      return simulationDetails.deliveryPrice.discounted === 0 ? t('chic.management.useSubscriptionOrder.deliveryBadge.free') : undefined;
    },
    [simulationDetails],
  );

  const onCreateOrder: () => void = (): void => {
    if (!subscriptionId) {
      return;
    }

    makeSubscriptionOrder(subscriptionId)
      .then((orderSubscriptionData: SubscriptionDetails): void => {
        showFullscreenAlert({
          title: t('chic.management.useSubscriptionOrder.makeSubscriptionOrder.success'),
          description: t('chic.management.useSubscriptionOrder.makeSubscriptionOrder.description'),
          nameValueFields: [
            {
              name: t('chic.management.useSubscriptionOrder.makeSubscriptionOrder.price'),
              value: `${parsePrice(orderSubscriptionData.orders[orderSubscriptionData.orders.length - 1].price)} ${currency}`,
            },
          ],
          acceptButtonSettings: {
            label: t('chic.management.global.close'),
            action: (): void => {
              hideFullscreenAlert();
              goBack();
            },
          },
        });
      })
      .catch((error: FrontendApiError): void => addToast({ content: error.firstErrorTranslated ?? error.message ?? '' }));
  };

  return { goBack, detailsInfoSettings, products, subscriptionDetails, deliveryAddress, onCreateOrder, deliveryBadge };
};
