import { Breakpoint, CalcSize, Color, FontWeight, Grid, StyledComponent, TransactionProductBox } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${Breakpoint.Mobile} {
    background-color: ${Color.ICBlack400};
    border-top: 1px solid ${Color.ICGray500};
  }
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
  
  @media ${Breakpoint.Mobile} {
    padding: 30px 24px;
    border-bottom: 1px solid ${Color.ICGray500};
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICTablet} + 48px);
  width: 100%;
  padding: 0 24px;
  margin: 62px 0;

  @media ${Breakpoint.Mobile} {
    margin: 40px 0;
  }
`;

export const DetailsInfoGroupBox: StyledComponent<'div'> = styled.div`
  padding: 20px 30px;
  background: linear-gradient(85.01deg, ${Color.ICBlack100} 0.65%, ${Color.ICBlack500} 110.02%);
  margin: 20px 0 40px;
`;

export const DetailsBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 40px;
`;

export const DetailsEditBoxes: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 20px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const DetailsPaymentInfo: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 16px 20px;
  background-color: ${Color.ICBlue};
  clip-path: polygon(-12px 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, -12px 100%);
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const DetailsPaymentInfoLabel: StyledComponent<'p'> = styled.p`
  font-size: 14px;
  font-weight: ${FontWeight.SemiBold};
  line-height: 18px;
  color: ${Color.ICNavy};
`;

export const ProductsBox: StyledComponent<'div'> = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${Color.ICGray500};
  border-bottom: 1px solid ${Color.ICGray500};
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 24px 0;
  
  & + & {
    border-top: 1px solid ${Color.ICGray500};
  }
`;

export const ButtonsBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 40px;
  background: linear-gradient(85.01deg, ${Color.ICBlack100} 0.65%, ${Color.ICBlack500} 110.02%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 16px;
    padding: 28px 20px;
  }
`;
