export enum RoutingPath {
  // common paths
  Home = '/',
  ReturnFromCrm = '/login/crm',
  Dashboard = '/panel',
  DiscountCodesSearch = '/kody-rabatowe',
  DiscountCodeDetails = '/kody-rabatowe/:code',
  SignIn = '/logowanie-wewnetrzne',
  RemindPassword = '/przypomnij-haslo',
  ChangePassword = '/zmiana-hasla',
  CrmSignIn = '/logowanie',
  CrmSignInRedirect = '/zaloguj-przez-crm',
  Profile = '/profil',
  UserRoleVerificationList = '/weryfikacja-uzytkownikow',
  UserRoleVerification = '/weryfikacja-uzytkownikow/:userId',
  NotFound = '*',
  // custom paths for forks apps
  AdAdd = '/zarzadzanie-animacjami/:type/dodaj-animacje',
  AdAddScreen = '/zarzadzanie-animacjami/:type/dodaj-ekran/:screenType',
  AdEdit = '/zarzadzanie-animacjami/:type/edycja-animacji/:id',
  AdEditScreen = '/zarzadzanie-animacjami/:type/edycja-ekranu/:screenType/:id',
  AdsList = '/zarzadzanie-animacjami',
  RefundsList = '/zwroty',
  SubscriptionList = '/inspiration-plan',
  SubscriptionDetails = '/inspiration-plan/:subscriptionId',
  SubscriptionOrder = '/inspiration-plan/:subscriptionId/nowe-zamowienie',
}
