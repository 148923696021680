import { PermissionScopeName, RoutingPath } from '@chic/enums';
import { ConfigsContextType, MenuPosition, UseRoutingPermissions } from '@chic/models';
import { useAuth } from './useAuth.hook';
import { useConfig } from './useConfig.hook';

export const useRoutingPermissions: () => UseRoutingPermissions = (): UseRoutingPermissions => {
  const { authData } = useAuth();
  const config: ConfigsContextType = useConfig();

  const isMenuPositionAvailable = (position: MenuPosition): boolean => {
    if (
      position.requiredPermissions
      && !position.requiredPermissions.every((scope: PermissionScopeName) => authData.user?.scopes.includes(scope))
    ) {
      return false;
    }
    if (position.pathname && config.disabledViews.includes(position.pathname)) {
      return false;
    }

    return true;
  };

  const checkRoutePermission: (pathname: RoutingPath | string) => boolean = (pathname: RoutingPath | string): boolean => {
    if (authData.user) {
      switch (pathname) {
        // common paths
        case RoutingPath.Home:
        case RoutingPath.Dashboard:
          return authData.user.scopes.includes(PermissionScopeName.AuthUser);
        case RoutingPath.Profile:
          return authData.user.scopes.includes(PermissionScopeName.AuthUserProfile);
        case RoutingPath.NotFound:
          return true;
        case RoutingPath.UserRoleVerification:
          return authData.user.scopes.includes(PermissionScopeName.UserVerificationVerify);
        case RoutingPath.UserRoleVerificationList:
          return authData.user.scopes.includes(PermissionScopeName.UserVerificationList);
        case RoutingPath.DiscountCodesSearch:
          return authData.user.scopes.includes(PermissionScopeName.BackofficePromotionCodesList);
        case RoutingPath.DiscountCodeDetails:
          return authData.user.scopes.includes(PermissionScopeName.BackofficePromotionCodesDetails);
        case RoutingPath.SubscriptionList:
          return authData.user.scopes.includes(PermissionScopeName.BackofficeSubscriptionsSearch);
        case RoutingPath.SubscriptionDetails:
          return authData.user.scopes.includes(PermissionScopeName.BackofficeSubscriptionsDetails);
        case RoutingPath.SubscriptionOrder:
          return authData.user.scopes.includes(PermissionScopeName.BackofficeSubscriptionsOrderCreate);
        case RoutingPath.AdsList:
          return [
            PermissionScopeName.BackofficeAdsSetupsList, 
            PermissionScopeName.BackofficeAdsDevicesRefresh, 
            PermissionScopeName.StaticAdsDictionary,
          ].every(
            ((scope: PermissionScopeName): boolean => !!authData.user?.scopes.includes(scope)),
          );
        case RoutingPath.AdAdd:
        case RoutingPath.AdAddScreen:
        case RoutingPath.AdEdit:
        case RoutingPath.AdEditScreen:
          return [
            PermissionScopeName.StaticAdsDictionary, 
            PermissionScopeName.BackofficeAdsAssetsCreate,
            PermissionScopeName.BackofficeAdsAssetsDelete,
            PermissionScopeName.BackofficeAdsAssetsDetails,
            PermissionScopeName.BackofficeAdsAssetsEdit,
            PermissionScopeName.BackofficeAdsAssetsList,
            PermissionScopeName.BackofficeAdsFileUpload,
            PermissionScopeName.BackofficeAdsScreensCreate,
            PermissionScopeName.BackofficeAdsScreensDelete,
            PermissionScopeName.BackofficeAdsScreensDetails,
            PermissionScopeName.BackofficeAdsScreensEdit,
            PermissionScopeName.BackofficeAdsScreensList,
            PermissionScopeName.BackofficeAdsSetupsCreate,
            PermissionScopeName.BackofficeAdsSetupsDelete,
            PermissionScopeName.BackofficeAdsSetupsList,
            PermissionScopeName.BackofficeAdsSetupsDetails,
            PermissionScopeName.BackofficeAdsSetupsEdit,
          ].every(
            ((scope: PermissionScopeName): boolean => !!authData.user?.scopes.includes(scope)),
          );
        case RoutingPath.RefundsList:
          return [PermissionScopeName.BackofficeSubscriptionsRefundsList, PermissionScopeName.BackofficeSubscriptionsRefundsAccept].every(
            ((scope: PermissionScopeName): boolean => !!authData.user && authData.user.scopes.includes(scope)),
          );
        default:
          return false;
      }
    }

    return false;
  };

  return { checkRoutePermission, isMenuPositionAvailable };
};
