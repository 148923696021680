import { 
  Breakpoint,
  Button,
  ButtonTheme,
  Color,
  ComponentColorTheme, 
  DetailsEditBox, 
  DetailsInfoGroup, 
  Icon, 
  IconName, 
  PageTitle, 
  PageTitleSize, 
} from '@chic-loyalty/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  ButtonsBox,
  Container, 
  DetailsBox, 
  DetailsEditBoxes, 
  DetailsInfoGroupBox, 
  DetailsPaymentInfo, 
  DetailsPaymentInfoLabel, 
  InnerContainer, 
  PageTitleWrapper,
  ProductsBox,
  StyledTransactionProductBox, 
} from './subscriptionOrder.styled';
import { UseSubscriptionOrder } from './subscriptionOrder.types';
import { useSubscriptionOrder } from './subscriptionOrder.hooks';
import { Params, useParams } from 'react-router-dom';
import { SubscriptionProduct, UseStatics } from '@chic/models';
import { useStatics, useTitle } from '@chic/hooks';
import { SubscriptionPaymentType } from '@chic/enums';
import { useMediaQuery } from 'react-responsive';

export const SubscriptionOrderView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { subscriptionId }: Params = useParams();
  const { 
    goBack, 
    detailsInfoSettings, 
    products, 
    deliveryAddress, 
    subscriptionDetails, 
    onCreateOrder,
    deliveryBadge,
  }: UseSubscriptionOrder = useSubscriptionOrder(subscriptionId ?? '');
  const { getPaymentNameByType }: UseStatics = useStatics();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  useTitle(t('chic.management.subscriptionOrderView.title'));

  if (!subscriptionDetails) { 
    return <></>;
  }

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle 
          size={PageTitleSize.Big} 
          label={t('chic.management.subscriptionOrderView.pageTitle')} 
          onClick={goBack}
        />
      </PageTitleWrapper>
      <InnerContainer>
        <PageTitle label={t('chic.management.subscriptionOrderView.basicData.title')} />
        <DetailsInfoGroupBox>
          <DetailsInfoGroup items={detailsInfoSettings} />
        </DetailsInfoGroupBox>
        <PageTitle label={t('chic.management.subscriptionOrderView.details.title')} />
        <DetailsBox>
          <DetailsEditBoxes>
            <DetailsEditBox 
              title={t('chic.management.subscriptionOrderView.details.address')} 
              textContent={deliveryAddress} 
              badge={deliveryBadge}
            />
            <DetailsEditBox 
              title={t('chic.management.subscriptionOrderView.details.payment')} 
              textContent={getPaymentNameByType(subscriptionDetails.contract.paymentType)} 
            />
          </DetailsEditBoxes>
          {[SubscriptionPaymentType.Card, SubscriptionPaymentType.CardPayment].includes(subscriptionDetails.contract.paymentType) && (
            <DetailsPaymentInfo>
              <Icon name={IconName.TriangleShape} color={Color.ICBlueDark} size={22} />
              <DetailsPaymentInfoLabel>{t('chic.management.subscriptionOrderView.details.infoAboutPayment')}</DetailsPaymentInfoLabel>
            </DetailsPaymentInfo>
          )}
        </DetailsBox>
        <PageTitle label={t('chic.management.subscriptionOrderView.products.title')} />
        <ProductsBox>
          {!!products.length && products.map((product: SubscriptionProduct): JSX.Element => (
            <StyledTransactionProductBox 
              key={product.productId}
              name={product.productName}
              category={product.categoryName}
              amount={product.amount}
              price={product.unitPrice}
              colorTheme={ComponentColorTheme.IC}
            />
          ))}
        </ProductsBox>
      </InnerContainer>
      <ButtonsBox>
        <Button 
          label={t('chic.management.global.cancel')} 
          buttonTheme={ButtonTheme.ICSecondary} 
          onClick={goBack}
          reverted={!isMobile}
          fullWidth={isMobile}
        />
        <Button 
          label={t('chic.management.subscriptionOrderView.createOrder')} 
          buttonTheme={ButtonTheme.ICPrimary} 
          onClick={onCreateOrder} 
          fullWidth={isMobile}
        />
      </ButtonsBox>
    </Container>
  );
};
